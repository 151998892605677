<script lang="ts">
  export let size: "lg" | "med" | "sm" = "med";
  export let fillSpace: boolean = false;
  export let noFlex: boolean = false;
  export let label: string = "";
</script>

<div class:spinner-holder={!noFlex} class:fill-space={!noFlex && fillSpace}>
  {#if label}
    <p>{label}</p>
  {/if}
  <div role="status" class="spinner-border spinner-border-{size}">
    <span class="visually-hidden">{label || "Loading..."}</span>
  </div>
</div>

<style>
  p {
    margin-bottom: 0.5rem;
  }
  .spinner-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .spinner-border-lg {
    width: 3rem;
    height: 3rem;
    border-width: 0.5em;
  }
  .fill-space {
    height: 100%;
    width: 100%;
  }
</style>
